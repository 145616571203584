import React, { useState } from 'react';
import { Layout, Menu, Avatar } from 'antd';
import { UserOutlined, HomeOutlined } from '@ant-design/icons';
// import logo from '../../assets/logo.png'; //not yet used 
import img from '../../assets/img.svg';
import './AppHeader.css';  // Import the CSS file
import {FaUser,FaClipboardList,FaEnvelope} from 'react-icons/fa';

const { Header } = Layout;
const AppHeader = ({scrollToSection}) => {
    const handleMenuClick = (section) => {
        setSelection(section);
        scrollToSection.current(section);
    };

    const [selected,setSelection] = useState('Home');

    return (
        <Layout>
            <Header className="app-header">
                <img src={img} alt="Logo" className="app-logo" />
                <div theme="dark" className='maintitle'>Club Sports Solutions</div>
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} className="app-menu" style={{backgroundColor:'transparent'}} >
                    <Menu.Item key="1" icon={<HomeOutlined />} onClick={() => handleMenuClick('Home')} style={{backgroundColor:'transparent'}}>
                        <div className={`Homediv ${selected==='Home'?'selected':''}`}>Home</div>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<FaUser/>} style={{backgroundColor:'transparent'}} onClick={()=>handleMenuClick('About')}>
                        <div className={`AboutDiv ${selected==='About'?'selected':''}`}>About</div>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<FaClipboardList/>} style={{backgroundColor:'transparent'}} onClick={()=>handleMenuClick('Services')}>
                        <div className={`ServicesDiv ${selected==='Services'?'selected':''}`}>Services</div>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<FaEnvelope/>} style={{backgroundColor:'transparent'}} onClick={()=>handleMenuClick('Contact')}>
                        <div className={`ContactDiv ${selected === 'Contact'?'selected':''} `}>Contact us</div>
                    </Menu.Item>
                </Menu>
                <Avatar icon={<UserOutlined />} className="app-avatar" />
            </Header>
        </Layout>
    );
};

export default AppHeader;