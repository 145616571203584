import React from 'react';
import './ImageGrid.css';

const ImageGrid = () => {
  const images = [
    '/featurespics/courtreservation.png',
    '/featurespics/tournamentplanner.png',
    '/featurespics/membershipManagement.png',
    '/featurespics/Dashboards.png',
    '/featurespics/Notifications.png',
    '/featurespics/curriculumTracker.png',
    '/featurespics/inventoryManagement.png'
    // '/featurespics/POS.png'
  ];

  return (
    <div className="image-container">
      <div className="spinner"></div>
      {images.map((src, index) => (
        <img key={index} src={src} alt={`Image ${index + 1}`} className={`image img-${index + 1}`} />
      ))}
    </div>
  );
};

export default ImageGrid;