import React, { useEffect, useState } from 'react';
import { Row,Col, Button } from 'antd';
import './AboutUsPage.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { DownOutlined } from '@ant-design/icons';

export const AboutUsPage=({title,description,image})=>{
    useEffect(()=>{
        Aos.init({duration:2000})
    },[])

    const [showAboutUs, setShowAboutUs] = useState(true);

    // // Function to handle scroll event
    // const handleScroll = () => {
    //     if (window.scrollY > 50) {
    //         setShowAboutUs(false);
    //     } else {
    //         setShowAboutUs(true);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    //     };
    // }, []);

return (
    
    <div className='new-feature'>
        <Row span={12} className='feature-row'>
            <Col span={12} className="feature-col">
                <img className='new-image' src={image} alt='Services'></img>
            </Col>
            <Col span={12} className='feature-col-text' data-aos='zoom-in'> 
                <h1 className='aboutus-title'>{title}</h1>
                <p className='content'>{description}</p>
                <Button className="demo-button">Request a Demo Today</Button>
            </Col>
            {showAboutUs && (
                            <div className="features-section">
                                <p className="features-text">Our Features</p>
                                <DownOutlined className="features-down-arrow-icon" />
                            </div>
                        )}
        </Row>
    </div>
)
};