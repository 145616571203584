import React,{useEffect} from 'react';
import { Row, Col } from 'antd';
import './RightFeature.css'; // Import custom CSS if needed
import Aos from 'aos';
import "aos/dist/aos.css"

export const RightFeature = React.forwardRef(({ features, animeSrc, animeQuote, animeName, imageSrc, title, description, animation }, ref) => {
    useEffect(()=>{
        Aos.init({duration:2000})
    },[])
    return (
        <div className="right-feature" > 
            <Row className="right-feature-row" >
                <Col span={9} className="right-feature-image" data-aos={animation}>
                <img src={imageSrc} alt={animeName} className="right-feature-responsive-image"/>
                </Col>
                <Col span={9} data-aos="fade-right" className="right-feature-text">
                    <h1 className="right-title">{title}</h1>
                    <ul>
                        <li>
                            <p className="left-content">{features[0]}</p>
                            {/* Customers can easily reserve courts through our platform. */}
                        </li>
                        <li>
                            <p className="left-content">{features[1]}</p>
                            {/* Manage court availability, update schedules, modify, or cancel reservations as needed. */}
                        </li>
                        <li>
                            <p className="left-content">{features[2]}</p>
                            {/* Manage reservations through our mobile app or website. */}
                        </li>
                        <li>
                            <p className="left-content">{features[3]}</p>
                            {/* Simplify the court booking process for both you and your customers. */}
                        </li>
                    </ul>
                </Col>
                <Col span={6} data-aos="fade-right" className="right-feature-message">
                    <img src={animeSrc} alt="Anime_Image" className="right-feature-message-image"/>
                    <p className="message">"{animeQuote}" - {animeName}</p>
                </Col>
            </Row>
            <div className="horizontal-line"></div>
        </div>
    );
});

