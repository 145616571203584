import React, {useState, useEffect, useRef} from 'react';
import AppHeader from './app/header/AppHeader';
import AppBody from './app/body/AppBody';
import { AppFooter } from './app/footer/AppFooter';

// Warning component to show on mobile/tablet devices
const WarningPage = () => (
  <div style={{ textAlign: 'center', padding: '20px' }}>
    <h1>Optimized for Laptop or Desktop</h1>
    <p>Currently, this site is best viewed on a laptop or desktop for the optimal experience.</p>
    <p>Please visit <a href="www.clubsportssolutions.com">www.clubsportssolutions.com</a> on a laptop browser</p>
    <img 
      src='/underconstruction.png' 
      height={300} 
      style={{ 
        borderRadius: '15px', 
        boxShadow: '4px 6px 12px rgba(238, 32, 5, 0.4)' 
      }} 
    />
  </div>
);


const App = () => {
  const [isMobile, setIsMobile] = useState(false);
  const scrollToSection = useRef(null);

  useEffect(() => {
    const checkDevice = () => {
      // Define width threshold for mobile and tablet (768px or 1024px is commonly used)
      setIsMobile(window.innerWidth < 1024);
    };

    checkDevice(); // Run the check on component mount

    // Optional: Add an event listener for screen resizing
    window.addEventListener('resize', checkDevice);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', checkDevice);
    };
  }, []);

  return (
    <div>
      {isMobile ? (
        <WarningPage /> 
      ) : (
        <div>
          {/* <CurlPage/> */}
          <AppHeader scrollToSection={scrollToSection}/>
          <AppBody scrollToSection={scrollToSection}/>
          <AppFooter scrollToSection={scrollToSection}/>
        </div>
      )}
      
    </div>
  );
};

export default App;