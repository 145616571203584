import React from "react";
import "./FooterItem.css";

export const FooterItem = ({footerTitle, footerItems}) => {
    return (
            <div className="footer-tags">
                <p className="footer-header">{footerTitle}</p>
                <table>
                    {footerItems.map((elementName,index)=>(
                        <tr key={index}>{elementName}</tr>
                    ))}
                </table>
            </div>
    )
 }