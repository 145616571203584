import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './IntroPage.css'; // Import custom CSS if needed
import ImageGrid from './ImageGrid';

export const IntroPage = () => {
    const [showAboutUs, setShowAboutUs] = useState(true);

    // Function to handle scroll event
    const handleScroll = () => {
        if (window.scrollY > 50) {
            setShowAboutUs(false);
        } else {
            setShowAboutUs(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
        };
    }, []);

    return (
        <div className="intro-body">
            <Row className="intro-row" gutter={0}>
                <Col span={11} offset={1} className="intro-text">
                    <div data-aos="fade-right" className='intro-anim'>
                        <h1>
                            <span className="word1">Club </span> 
                            <span className="word2">Sports </span> 
                            <span className="word3">Solutions </span>
                        </h1>
                        <h1 className="intro-subtext" data-aos='fade-right' data-aos-delay='400'>
                            Your ultimate sports club management tool.
                        </h1>
                        <div className="contact-button" data-aos-delay='1200'>
                            <Button type="primary" >
                                Contact Us
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col span={11} offset={1}>
                    <ImageGrid />    
                </Col>
                {showAboutUs && (
                            <div className="about-us-section">
                                <p className="about-us-text">About Us</p>
                                <DownOutlined className="down-arrow-icon" />
                            </div>
                        )}
            </Row>
        </div>
    );
};