import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import './LeftFeature.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

export const LeftFeature = ({ features, animeSrc, animeQuote, animeName, imageSrc, title, description, animation }) => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    return (
        <div className="left-feature">
            <Row className="intro-row">
                <Col span={6} data-aos="fade-right" className="left-feature-message">
                    <img src={animeSrc} alt="Anime_Img" className="left-feature-message-image"/>
                    <p className="message">"{animeQuote}" - {animeName}</p>
                </Col>
                <Col span={9}  data-aos="fade-left">
                    <h1 className="left-title">{title}</h1>
                    <ul>
                        <li>
                            <p className="left-content">{features[0]}</p>
                            {/* Customers can easily reserve courts through our platform. */}
                        </li>
                        <li>
                            <p className="left-content">{features[1]}</p>
                            {/* Manage court availability, update schedules, modify, or cancel reservations as needed. */}
                        </li>
                        <li>
                            <p className="left-content">{features[2]}</p>
                            {/* Manage reservations through our mobile app or website. */}
                        </li>
                        <li>
                            <p className="left-content">{features[3]}</p>
                            {/* Simplify the court booking process for both you and your customers. */}
                        </li>
                    </ul>
                </Col>
                <Col span={9} className="intro-image" data-aos={animation}>
                    <img src={imageSrc} alt={animeName} className="responsive-image" />
                </Col>
            </Row>
            <div className="horizontal-line"></div>
        </div>
    );
};